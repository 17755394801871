<template>
  <div class="login">
    <div class="login__container">
      <div class="login__imageWrapper">
        <img
          class="login__logo"
          src="@/assets/trendfund_logo-transparent.png"
        />
      </div>
      <div class="login__wrapper">
        <div class="login__title">登入</div>
        <div class="login__inputWrapper">
          <div class="login__input">
            <input type="mail" placeholder="Email" v-model="accountEmail" />
            <a-icon type="mail" />
          </div>
          <div class="login__input">
            <input
              @keypress.enter="handleLoginUser"
              type="password"
              placeholder="Password"
              v-model="accountPassword"
            /><a-icon type="key" />
          </div>
          <button class="login__loginButton" @click="handleLoginUser">
            登入
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      accountEmail: "",
      accountPassword: "",
    };
  },
  components: {},
  methods: {
    async handleLoginUser() {
      try {
        await this.$store.dispatch("firebaseLogin", {
          email: this.accountEmail,
          password: this.accountPassword,
        });
        this.$router.push({ name: "main" });
        setTimeout(() => {
          this.$notification.open({
            message: "登入成功",
            description: `歡迎回來!`,
          });
        }, 0);
      } catch (error) {
        console.log(error);
        this.$notification.open(
          {
            message: "登入失敗",
            description: `請確認帳號密碼輸入無誤`,
          },
          0
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/rwd.scss";
@import "@/scss/main.scss";

.login {
  background: rgb(227,227,227);
  background: linear-gradient(142deg, rgba(227,227,227,1) 0%, rgba(68,108,170,0.8827906162464986) 100%);
  height: 100%;
  &__container {
    width: 380px;
    @include phone {
      width: 90%;
    }
    margin: 0px auto;
    transform: translateY(30%);
  }
  &__wrapper {
    background-color: $white;
    padding: 15px 20px;
    border-radius: 4px;
  }
  &__imageWrapper {
    margin-bottom: 40px;
  }
  &__logo {
    width: 200px;
  }
  &__title {
    font-size: 24px;
    margin-top: 20px;
  }
  &__inputWrapper {
    padding: 20px 0 50px;
  }
  &__input {
    margin-bottom: 20px;
    border-bottom: solid 1px $borderGrey;
    position: relative;
    input {
      width: 100%;
      height: 50px;
      background-color: $white;
      padding: 10px;
      border: none;
      outline: none;
      &:focus{
        border-bottom: solid 1px $blue;
        border: none;
        outline: none;
      }
      &:active{
        border-bottom: solid 1px $blue;
        border: none;
        outline: none;
      }
    }
    i {
      position: absolute;
      top: 15px;
      right: 30px;
    }
  }
  &__loginButton {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    font-size: 26px;
    background-color: #3f68a8;
    color: #fff;
    border: none;
  }
}
</style>
