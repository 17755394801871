<template>
  <div class="index">
    <Sidebar></Sidebar>
    <Main></Main>
  </div>
</template>

<script>
import Main from "@/components/main";
import Sidebar from "@/components/Sidebar";
export default {
  name: "index",
  components: {
    Sidebar,
    Main,
  },
  methods: {
    handleLogout() {
      localStorage.removeItem("loginToken");
      this.$router.push({ name: "login" });
    },
  },
};
</script>
<style lang="scss" scoped>
.index{
  display: flex;
  height: 100%;
}
</style>
