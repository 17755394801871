<template>
  <transition enter-active-class="fade-in" leave-active-class="fade-out">
    <div class="sidebar" :class="{ sideHide: !getIsSidebarShow }">
      <div class="logoContainer">
        <img class="logo" src="@/assets/trendfund_logo.png" />
        <div class="sidebarSwitch" @click="toggleSidebar">
          <a-icon type="menu-unfold" />
        </div>
      </div>
      <div class="sidebar__item">
        <router-link to="/index">資訊主頁</router-link>
      </div>
      <div class="sidebar__item">
        <router-link :to="{ name: 'table' }">統計資料</router-link>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Sidebar",
  computed: {
    ...mapGetters({
      getIsSidebarShow: "getIsSidebarShow",
      getIsDesktop: "getIsDesktop",
    }),
  },
  methods: {
    ...mapActions({
      toggleSidebar: "toggleSidebar",
    }),
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/main.scss";

@mixin phone {
  @media (max-width: 428px) {
    @content;
  }
}
.sidebar {
  width: 15%;
  color: $fontBlcak;
  background-color: $white;
  border-right: 1px solid $borderGrey;
  transition: transform 0.4s;
  position: relative;
  &.sideHide {
    transform: translateX(-200px);
  }

  .sidebarSwitch {
    display: none;
  }
  @include phone {
    .sidebarSwitch {
      display: inline;
      position: absolute;
      right: 18px;
    }
  }
  .logoContainer {
    width: 80%;
    margin: 20px 20px 20px 10px;
    text-align: center;
    .logo {
      width: 60%;
    }
  }
  a {
    color: $fontBlcak;
    font-size: 15px;
    font-weight: 500;
  }
  @include phone {
    width: 200px;
    position: fixed;
    height: 100%;
    z-index: 1;
  }
  &__item {
    height: 25px;
    margin: 40px;
    text-align: center;
    i {
      color: $black;
    }
  }
}
.fade-in {
  animation: go 0.5s;
}

.fade-out {
  animation: back 0.5s;
}

@keyframes go {
  from {
    transform: translateX(-200px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes back {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-200px);
  }
}
</style>
