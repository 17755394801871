import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/compat/app';
import { getAuth, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, onValue, set, push, update, child} from "firebase/database";

Vue.use(Vuex)

let firebaseObject = {
  db: null,
  auth: null,
}

export default new Vuex.Store({
  state: {
    firebaseInstance: null,
    firebaseUser: null,
    basicInfo: {
      name: ''
    },
    investInfo: {},
    keyMap:{},
    globalData:{},
    isSidebarShow: true,
    isDesktop: true,
    testVuex: false,
    newUserUid:'',
  },
  mutations: {
    setFirebaseInstance(state, value) {
      state.firebaseInstance = value;
    },
    setFirebaseUser(state, value) {
      state.firebaseUser = value;
    },
    setBasicInfo(state, value) {
      if(value === null){
        state.basicInfo = {
          name: '',
        }
      }else{
        state.basicInfo = value
      }
    },
    setInvestInfo(state, value) {
      state.investInfo = value;
    },
    setKeyMap(state, value) {
      state.keyMap = value;
    },
    toggleSidebarShow(state) {
      state.isSidebarShow = !state.isSidebarShow
    },
    setIsSidebarShow(state, value) {
      if(window.innerWidth < 360){
        state.isSidebarShow = value;
      }
    },
    setIsDesktop(state, value) {
      state.isDesktop = value
    },
    setGlobalData(state,value){
      state.globalData = value
    },
    setTestVuex(state, value) {
      state.testVuex = value
    },
    setNewUserUid(state, value){
      state.newUserUid = value;
    },
    setInterestRate(state, value){
      state.interestRate = value;
    }
  },
  actions: {
    setFirebaseInstance({ commit }) {
      const config = {
        apiKey: 'AIzaSyDfPvq5o7swgOoP3OHGKSd9EraAUM7X3IM',
        authDomain: 'trendfund-a8832.firebaseapp.com',
        databaseURL: 'https://trendfund-a8832.firebaseio.com',
        projectId: 'trendfund-a8832',
        storageBucket: 'trendfund-a8832.appspot.com',
        messagingSenderId: '401307491256',
        appID: '1:401307491256:web:cb2071846545f0d0700d4d'
      }
      const firebaseInstance = firebase.initializeApp(config)
      commit('setFirebaseInstance', firebaseInstance)
    },
    async firebaseLogin({ commit, dispatch }, { email, password }) {
      firebaseObject.auth = getAuth();
      await signInWithEmailAndPassword(firebaseObject.auth, email, password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          commit('setFirebaseUser', user)
          await dispatch('getFirebaseInfo')
          localStorage.setItem('uid', JSON.stringify(user.uid))
          return Promise.resolve()
        })
        .catch(() => {
          return Promise.reject()
        });
    },
    async getFirebaseInfo({ dispatch }) {
      await dispatch('getBasicInfo');
      await dispatch('getInvestInfo');
    },
    firebaseSignOut({ commit, dispatch }) {
      firebaseObject.auth = getAuth();
      return new Promise((resolve, reject) => {
        signOut(firebaseObject.auth).then(() => {
          dispatch('clearAllUserStore')
          resolve()
        }).catch(() => {
          reject()
        })
      })

    },
    getBasicInfo({ commit, state }) {
      firebaseObject.db = getDatabase();
      const userId = state.firebaseUser.uid;
      const starCountRef = ref(firebaseObject.db, `/users/${userId}/basicInfo`);

      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        console.log(data, "userData");
        commit('setBasicInfo', data)
      });
    },
    getInvestInfo({ commit, state }) {
      firebaseObject.db = getDatabase();
      const userId = state.firebaseUser.uid;
      const starCountRef = ref(firebaseObject.db, `/users/${userId}/investInfo`);

      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        commit('setInvestInfo', data)
      });
    },
    getKeyMap({ commit }) {
      firebaseObject.db = getDatabase();
      // const userId = firebaseObject.auth.currentUser.uid;
      const starCountRef = ref(firebaseObject.db, `/keyMaps`);

      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        commit('setKeyMap', data)
      });
    },
    getGlobalData({ commit }){
      firebaseObject.db = getDatabase();
      const starCountRef = ref(firebaseObject.db, `/globalData`);

      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        commit('setGlobalData', data)
      });
    },
    clearAllUserStore({ commit }) {
      commit('setFirebaseUser', null)
      commit('setBasicInfo', null)
      commit('setInvestInfo', null)
      localStorage.removeItem('uid')
      console.log(localStorage.getItem('uit'), 'after logout')
    },
    toggleSidebar({ commit }) {
      commit('toggleSidebarShow')
    },
    setIsSidebarShow({ commit }, value) {
      commit('setIsSidebarShow', value)
    },
    setIsDesktop({ commit }, value) {
      commit('setIsDesktop', value)
    },
    setNewUser({ commit }, { email, password }) {
      console.log('setNewUser', email, password)
      firebaseObject.auth = getAuth();
      return new Promise((resolve,reject)=>{
        createUserWithEmailAndPassword(firebaseObject.auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          console.log(user, 'user setNewUser')
          this.dispatch('setUserData', user.uid)
          commit('setNewUserUid', user.uid)
          resolve(user.uid)

          // ...
        })
        .catch(() => {
          reject()
          // ..
        });
      })

    },
    setUserData({ getters, state }, uid) {
      const db = getDatabase();
      console.log('setUserData')
      set(ref(db, 'users/' + uid), 
      {
        "basicInfo": {
          "name": "未設定"
        },
        "investInfo": {
          "balance": 0,
          "contractStartDate": "未設定",
          "isInterestWithdraw": false,
          "principal": 0,
          "profit": 0
        }
      });
    },
    setInterestRate({ getters, state }, dataArray) {
      const db = getDatabase();
      const starCountRef = ref(db, `/globalData/interestRate`);
      return set(starCountRef, dataArray)
    },
  },
  getters: {
    basicInfoName: (state) => state.basicInfo.name,
    getKeyMap: (state) => state.keyMap,
    getInvestInfo: state => state.investInfo,
    getIsSidebarShow: state => state.isSidebarShow,
    getIsDesktop: state => state.isDesktop,
    getInterestRate: state => state.globalData.interestRate,
    getFirebaseUser: state => state.firebaseUser,
    getNewUserUid: state => state.newUserUid,
  },
  modules: {
  }
})
