import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import 'babel-polyfill';
import 'es6-promise/auto';
import VCharts from 'v-charts'


Vue.use(VCharts)
Vue.use(Antd);
Vue.config.productionTip = false
Vue.config.debug = true;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
