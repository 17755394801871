<template>
  <div class="header">
    <div class="header__container">
      <div class="header__item header__item--title">
        <div class="sidebarSwitch" @click="toggleSidebar"> <a-icon type="menu-unfold" /></div>
      </div>
      <div class="header__item header__item--title">
        <div class="text">歡迎回來！{{ basicInfoName ? basicInfoName : ''}}</div>
      </div>
      <div class="header__item header__item--logout" @click="handleLogout">
        <a-icon type="logout" /> 登出
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Header",
  components: {},
  computed: {
    ...mapGetters({
      basicInfoName: "basicInfoName"
    })
  },
  methods: {
    ...mapActions({
      toggleSidebar: "toggleSidebar"
    }),
    handleGoHome() {
      this.$router.push({ name: "main" });
    },
    async handleLogout() {
      try {
        await this.$store.dispatch("firebaseSignOut");
        localStorage.removeItem("loginToken");
        this.$router.push({ name: "login" });
        setTimeout(() => {
          this.$notification.open({
            message: "已經登出",
            description: `下次再見囉!`
          });
        }, 500);
      } catch (error) {
        alert("登出失敗");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/main.scss";

@mixin phone {
  @media (max-width: 428px) {
    @content;
  }
}

.header {
  height: 50px;
  color: $fontBlcak;
  &__container {
    position: relative;
    display: flex;
  }
  &__item {
    font-size: 22px;
    line-height: 50px;
    padding-left: 10px;
    .sidebarSwitch{
      display: none;
    }
    @include phone {
      font-size: 14px;
      .sidebarSwitch{
        display: block;
      }
    }
    &--logout {
      text-align: right;
      position: absolute;
      right: 10px;
      top: 0px;
      cursor: pointer;
    }
  }
}
</style>
