<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default {
  name: "app",
  components: {},
  methods: {
    ...mapActions({
      getKeyMap: "getKeyMap",
      setIsDesktop: "setIsDesktop",
      getFirebaseInfo: "getFirebaseInfo",
      getGlobalData: "getGlobalData",
    }),
    initFirebaseInstance() {
      this.$store.dispatch("setFirebaseInstance");
    },
    handleSidebarShow() {
      if (document.body.offsetWidth > 428) {
        this.setIsDesktop(true);
      } else {
        this.setIsDesktop(false);
      }
    },
    initAntConfig() {
      this.$notification.config({
        placement: "topRight",
        top: "40px",
        duration: 1.5
      });
    }
  },
  mounted() {
    this.initFirebaseInstance();
    this.getKeyMap();
    this.getGlobalData();
    this.handleSidebarShow();
    this.initAntConfig();
    const auth = getAuth();
    onAuthStateChanged(auth, user => {
      if (user) {
        const uid = user.uid;
        console.log(uid ,'onAuthStateChanged')
        this.$store.commit('setFirebaseUser', user)
        this.getFirebaseInfo()
      } else {
        console.log('User is signed out')
        // User is signed out
        // ...
      }
    });
  }
};
</script>
<style>
@import "/scss/_reset.scss";
@import "/scss/main.scss"; 
@import "/scss/rwd.scss";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500;700&display=swap');

#app {
  font-family: 'Noto Sans TC', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

img {
  max-width: 100%;
}
</style>
