<template>
  <div class="admin">
    <div class="admin__title">管理員您好！</div>
    <input type="text" placeholder="使用者Email" v-model="email" />
    <input type="text" placeholder="使用者密碼" v-model="password" />
    <button @click="handleAddNewUser">確認新增</button>
    <router-link :to="{ name: 'login' }"
      ><a-icon type="info-circle" />回到登入頁面</router-link
    >
    <div class="rate">
      <button class="rate__addBtn" @click="addRateItem">+</button>
      <div class="rate__item" v-for="(item, index) in dataArray" :key="index">
        <input type="month" placeholder="日期" v-model="item.date" />
        <input type="number" placeholder="利率" v-model="item.rate" />
      </div>
      <button @click="handleAddRate(dataArray)">確認添加利率</button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Admin",
  data() {
    return {
      email: "",
      password: "",
      dataArray: [
        {
          date: "",
          rate: "",
        },
      ],
    };
  },
  components: {},
  mounted() {
    console.log("mounted");
    setTimeout(() => {
      this.dataArray = JSON.parse(JSON.stringify(this.getInterestRate));
    }, 1000);
  },
  computed: {
    ...mapGetters({
      getFirebaseUser: "getFirebaseUser",
      getNewUserUid: "getNewUserUid",
      getInterestRate: "getInterestRate",
    }),
  },
  methods: {
    ...mapActions({
      setNewUser: "setNewUser",
      setUserData: "setUserData",
      setInterestRate: "setInterestRate",
    }),
    async handleAddNewUser() {
      try {
        let uid = await this.setNewUser({
          email: this.email,
          password: this.password,
        });
        alert(`新增使用者成功，uid為 ${uid}`);
        setTimeout(async () => {
          this.clearUserData();
        }, 10);
      } catch (error) {
        alert("新增使用者失敗");
      }
    },
    clearUserData() {
      this.email = "";
      this.password = "";
    },
    addRateItem() {
      this.dataArray.push({
        date: "",
        rate: "",
      });
    },
    handleAddRate(dataArray) {
      this.setInterestRate(dataArray)
        .then((res) => {
          console.log(res, "res in rate");
          this.$notification.open(
            {
              message: "新增成功",
            },
            0
          );
        })
        .catch((err) => {
          console.log(err, "err in addRate");
          this.$notification.open(
            {
              message: "新增失敗",
            },
            0
          );
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin phone {
  @media (max-width: 428px) {
    @content;
  }
}
.admin {
  &__title {
    font-size: 26px;
  }
}
</style>
