<template>
  <div class="main">
    <AppHeader></AppHeader>
    <div class="content" @click="handleClickContent">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import AppHeader from "@/components/header";
import { mapActions } from "vuex";

export default {
  name: "Main",
  components: {
    AppHeader
  },
  methods: {
    ...mapActions({
      toggleSidebar: "toggleSidebar",
      setIsSidebarShow: "setIsSidebarShow",
    }),
    handleClickContent(){
      this.setIsSidebarShow(false)
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/main.scss";


@mixin phone {
  @media (max-width: 428px) {
    @content;
  }
}

.main {
  height: 100%;
  width: 100%;
  background-color: $backgroundGrey;
  overflow-y: scroll;
  .content {
    width: 100%;
    height: calc(100% - 50px);
  }
  .sidebar {
    width: 10%;
    color: $fontBlcak;
    a {
      color: $fontBlcak;
    }
    @include phone {
      display: none;
    }
    &__item {
      height: 35px;
    }
  }
}
</style>
