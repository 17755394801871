import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index.vue'
import login from '@/views/login.vue'
import admin from '@/views/admin.vue'
import store from '@/store'
Vue.use(VueRouter)
console.log(store, 'store')
const routes = [
  { 
    path:'/',
    redirect: '/main'
  },
  {
    path: "*",
    redirect: '/main',
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/admin',
    name: 'admin',
    component: admin,
  },
  {
    path: '/main',
    name: 'main',
    redirect: '/main/dashboard',
    component: index,
    children:[
      {
        path:'/main/dashboard',
        name:'dashboard',
        component: () => import ('@/components/dashboard')
      },
      {
        path:'/main/table',
        name:'table',
        component: () => import ('@/components/dashboard/table')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next)=>{
  console.log(localStorage.getItem("uid"),localStorage.getItem("uid") === null, 'locaostorage')
  if(to.name !== 'login' && localStorage.getItem("uid") === null && to.name !== 'admin'){
    next('/login')
  }else{
    next()
    store.dispatch('setIsSidebarShow' , false)
  }
})

export default router
